import { REHYDRATE } from "redux-persist/lib/constants";

import orderEnum from "./enums/orderEnum";
import tagTypeEnum from "./enums/tagTypeEnum";
import * as userActions from "./actions/user";

export const STORE_SITE_ID = 1;
export const ORDER_SOURCE = 2;

export const COUNTRY_CODE = "SG";
export const COUNTRY_CODE_ALPHA3 = "SGP";
export const CURRENCY_CODE = "SGD";
export const COUNTRY_NAME = "Singapore";

export const CDN_BASE_URL = "https://d355e66csa32sa.cloudfront.net";

export const FACEBOOK_ROUTER_URL = "https://auth.prod.ggops.net/login/";

export const ECOM_BASE_URL = "https://ecom.prod.ggops.net/api/";
export const CLOUDFRONT_API_BASE_URL =
  "https://dtymvut4pk8gt.cloudfront.net/api/";
export const OAUTH_BASE_URL = "https://ecom.prod.ggops.net/oauth/";
export const AUTH2_BASE_URL = "https://auth.prod.ggops.net/api/";
export const PAYMENT_BASE_URL = "https://payment.prod.ggops.net/api/";
export const LOYALTY_BASE_URL = "https://app.loyalty.prod.sg.ggops.net/api/";
export const ESRI_GEOCODE_URL =
  "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"
export const KIOSK_NOTIFY_BASE_URL = 'https://ecom.prod.ggops.net/kiosknotify/';

  
export const ECOM_API_VERSION = 'v1';
export const OAUTH_API_VERSION = 'v1';
export const AUTH2_API_VERSION = 'v2';
export const PAYMENT_API_VERSION = 'v1';
export const LOYALTY_API_VERSION = 'v2';

export const LOYALTY_REDEEM_IN_STORE_PROMPT_SCAN = false;


/* Whitelist of pages that can be accessed directly. */
export const DIRECT_ACCESS_URLS = [
  "/order",
  "/browser",
  "/popup/payment",
  "/popup/facebook",
  "/reorder",
];

export const SALES_TAX_RATE = 0.07;
export const SALES_TAX_NAME = "GST";

export const PIN_DIGITS = 5;
export const MENU_DETAILS_ADD_SCROLL_DELAY = 200;

export const GGLOCATION_LOCATOR_NOTICE = "";

export const FACEBOOK_GRAPH_BASE_URL = "https://graph.facebook.com";

export const SENTRY_KEY = "e2e57a1e202a43009af81c94100f541d";
export const SENTRY_PROJECT = "6";
export const SENTRY_ENVIRONMENT = "production";
export const SENTRY_IGNORE_ERROR_MAPPING = {'Failed POST request': ['Invalid promo code', 'Receipt already scanned', 'Please key in a valid phone number', 'Store is closed'], 'Failed GET request': ['Failed to fetch', 'Load failed']};

export const SMARTLOOK_API = "a146696e367654e0bf549fba7fd2802b24cf75c5";
export const RECAPTCHA_SITE_KEY = "6LersBsiAAAAANmD_noUMpjre0sf04DY7vYwvoRK";

export const GTM_ID = "GTM-TGS2G56";

export const RAVEN_ACTION_TYPE_BLACKLIST = [
  REHYDRATE,
  userActions.PAYMENT_LOGIN_REQUESTED,
];
export const RAVEN_ENDPOINT_BLACKLIST = ["changePassword", "signUp", "login"];

export const FACEBOOK_PRODUCTION_APP_ID = "1856031981332093";
export const FACEBOOK_DEVELOPMENT_APP_ID = "1856361824632442";
export const FACEBOOK_APP_ID =
  process.env.NODE_ENV === "production"
    ? FACEBOOK_PRODUCTION_APP_ID
    : FACEBOOK_DEVELOPMENT_APP_ID;

export const PLACEHOLDER_IMAGE =
  "//d38i5z1l0hzlq4.cloudfront.net/static/home/images/cyo-photo-holder.3455814eec77.jpg";

export const PUSHER_APP_KEY = "06361494123a616b5ab0";
export const PUSHER_SETTINGS = {
  authEndpoint: "https://ecom.prod.ggops.net/pusher/auth/",
  cluster: "ap1",
  encrypted: true,
};
export const ECOM_CUSTOMER_PUSHER_CHANNEL = 'gogreen-customer-client';

export const CONTACT_EMAIL = 'support@saladstopgroup.com';
export const FACEBOOK_LINK = 'https://www.facebook.com/SaladStopsg/';
export const INSTAGRAM_LINK = 'https://www.instagram.com/saladstopsg/?hl=en';
export const OFFICIAL_SITE_LINK = 'http://saladstop.com/';

export const MAP_DEFAULT_ZOOM = 14;
export const MAP_MIN_ZOOM = 11;
export const MAP_MARKER_ICON =
  "//d38i5z1l0hzlq4.cloudfront.net/static/home/images/locator-marker.a123293b8fb6.svg";
export const DEFAULT_COORDINATES = {
  lat: 1.306697,
  lng: 103.903508,
};
export const ADDRESS_MAP_ADJUST_RADIUS = 200;
export const DEFAULT_COUNTRY_CODE = "+65";
export const POSTCODE_MIN_LENGTH = 5;
export const POSTCODE_MAX_LENGTH = 7;
export const POSTCODE_ALLOWED_CHARS = "0123456789";

export const ENERGY_ID = 1;

export const INGREDIENT_BASES = {
  216: 1,
  215: 2,
  217: 3,
};

export const CYO_SECTION_ROUTES = [
  {
    path: '/cyo/:menuItemId/1',
    className: 'ShoppingPage',
    caption: 'Change Base',
  },
  {
    path: '/cyo/:menuItemId/2',
    className: 'ShoppingPage',
    caption: 'Change Greens',
  },
  {
    path: '/cyo/:menuItemId/3',
    className: 'ShoppingPage',
    caption: 'Change Toppings',
  },
  {
    path: '/cyo/:menuItemId/4',
    className: 'ShoppingPage',
    caption: 'Change Dressing',
  },
  {
    path: '/cyo/:menuItemId/5',
    className: 'ShoppingPage',
    caption: 'Add Snack?',
  },
  {
    path: '/cyo/:menuItemId/:sectionId',
    className: 'ShoppingPage',
    caption: 'Add More',
  },
];

export const BASE_SECTION_ID = 1;

export const PROCESSING_TIME_MARGIN = 15;

export const COMPLETED_ORDERS_CUTOFF_TIME = [2, "hours"];

export const ORDER_ERROR_INTERPRETATION = {
  [orderEnum.ORDER_ERROR_TIMESLOT_INSTANCE_NOT_FOUND]: "TIME",
  [orderEnum.ORDER_ERROR_TIMESLOT_SCHEDULE_FULL]: "TIME",
  [orderEnum.ORDER_ERROR_TIMESLOT_NOT_EXISTS]: "TIME",
  [orderEnum.ORDER_ERROR_DUE_NOT_AVAILABLE]: "TIME",
  [orderEnum.ORDER_ERROR_STORE_BUSY]: "LOCATION",
  [orderEnum.ORDER_ERROR_PARTNER_INVALID]: "LOCATION",
  [orderEnum.ORDER_ERROR_STORE_INVALID]: "LOCATION",
  [orderEnum.ORDER_ERROR_ORDER_TYPE_INVALID]: "LOCATION",
  [orderEnum.ORDER_ERROR_PHONE_INVALID]: "PHONE_NUMBER",
  [orderEnum.ORDER_ERROR_USER_INVALID]: "TOKEN",
  [orderEnum.ORDER_ERROR_DUE_INVALID]: "TIME",
  [orderEnum.ORDER_ERROR_TIMESLOT_INVALID]: "TIME",
  [orderEnum.ORDER_ERROR_GGLOCATION_INVALID]: "LOCATION",
};

export const ORDER_DELAY_TIME = 4000;

export const LOYALTY_S3_DOMAIN =
  "https://s3-ap-southeast-1.amazonaws.com/gogreen-prod-loyalty/";

export const MOBILE_PREVIEW_NUTRIENTS = [7, 3, 2];

export const RECOMMENDED_GGLOCATIONS_LIMITS = {
  user: [
    { provider: "mostFrequent", limit: 2 },
    { provider: "mostRecent", limit: 1 },
  ],
  guest: [{ provider: "nearest", limit: 2 }],
  fallback: [{ provider: "nextAvailable", limit: 2 }],
};

// Tags that should be apply to ingredient level only
export const INGREDIENT_LEVEL_TAG_TYPES = [tagTypeEnum.ALLERGEN];
